import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/T&CView.vue')
  },
  {
    path: '/privacy-and-policy',
    name: 'privacy-and-policy',
    component: () => import(/* webpackChunkName: "privacy-and-policy" */ '../views/P&PView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
