<template>
  <v-app style="background-color: #f0f0f0">
    <AppToolbarVue />
    <AppDrawer/>
    <v-main>
      <v-slide-y-reverse-transition>
      <router-view v-show="show" />
    </v-slide-y-reverse-transition>
    </v-main>

    <v-footer>
      <v-container fluid>
        <v-row align="start" justify="start">
          <v-col md="8" cols="12">
            <p class="mb-0">© Copyright techferment.com. All Rights Reserved</p>
          </v-col>
          <v-col md="4" cols="12" style="text-align: right">
            <router-link
              style="text-decoration: none"
              to="/terms-and-conditions"
              class="mr-5"
              >Terms & Conditions</router-link
            >
            <router-link style="text-decoration: none" to="/privacy-and-policy"
              >Privacy & Policy</router-link
            >
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import AppDrawer from "./components/core/AppDrawer.vue";
import AppToolbarVue from "./components/core/AppToolbar.vue";

export default {
  name: "App",
  components: {
    AppToolbarVue,
    AppDrawer
  },
  data: () => ({
    show: false,
  }),
  created() {
    this.show = true;
  },
};
</script>

<style>
</style>
