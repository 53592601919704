<template>
  <v-main class="my-0 py-0">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col md="10">
          <v-container fluid>
            <!-- Home -->
            <v-row id="home" class="my-md-10 my-0" justify="center" align="center">
              <v-col md="7" cols="12" order-md="1" order="2">
                <p
                  style="font-size: 300%; color: #1565c0; line-height: 115%"
                  class="mb-2 tf-font"
                >
                  Frontend Bootcamp
                </p>
                <p class="mt-n1" style="font-size: 120%">
                  Zero to Hero - A Complete Pathway to Frontend Development
                  <v-icon>mdi-airplane</v-icon>
                </p>

                <p>
                  Unleash your creativity in our Frontend Development Bootcamp -
                  an immersive and dynamic learning experience. Master
                  cutting-edge technologies and craft captivating user
                  interfaces. Join a supportive community for Live Classes, 1:1
                  Mentorship, and hands-on projects. Elevate your skills with
                  in-depth discussions on design decisions and real-world
                  implementation. Launch your frontend career with confidence,
                  making your mark in the digital landscape.
                </p>
                <v-container
                  fluid
                  class="text-center"
                  style="background-color: white; border-radius: 12px"
                >
                  <v-row>
                    <v-col md="3" cols="6">
                      <p class="mb-0" style="font-size: 90%">Start From</p>
                      <p class="mb-0" style="font-size: 110%; color: #1565c0">
                        <b>March 1, 2024</b>
                      </p>
                    </v-col>
                    <v-col md="3" cols="6">
                      <p class="mb-0" style="font-size: 90%">Duration</p>
                      <p class="mb-0" style="font-size: 110%; color: #1565c0">
                        <b>2 Months</b>
                      </p>
                    </v-col>
                    <v-col md="3" cols="6">
                      <p class="mb-0" style="font-size: 90%">
                        Last Date to Apply
                      </p>
                      <p class="mb-0" style="font-size: 110%; color: #1565c0">
                        <b>Feb 25, 2024</b>
                      </p>
                    </v-col>
                    <v-col md="3" cols="6">
                      <p class="mb-0" style="font-size: 90%">Fees</p>
                      <p class="mb-0" style="font-size: 110%; color: #1565c0">
                        <b>1999/- INR</b>
                      </p>
                    </v-col>
                  </v-row>
                </v-container>

                <v-btn depressed color="primary" class="mt-5" rounded href="https://docs.google.com/forms/d/e/1FAIpQLSeneqxVIlv0COKQ2L9GuXOJhevx5WZkEVhhrXQ0ielUdUPNaA/viewform" target="_blank"
                  >Enroll Now</v-btn
                >
              </v-col>
              <v-col md="5" cols="12" class="text-center" order-md="2" order="1">
                <v-img style="width: 90%;margin-left: auto;margin-right: auto;" :src="require('@/assets/img/home.svg')"></v-img>
              </v-col>
            </v-row>
            <!-- Home -->

            <!-- Mission -->
            <v-row class="my-12" id="mission">
              <v-col md="8" sm="8" cols="12">
                <div
                  style="background-color: white; border-radius: 12px"
                  class="pa-md-10 pa-4"
                >
                  <p class="tf-font" style="color: #1565c0; font-size: 250%">
                    Mission
                  </p>
                  <p>
                    The primary mission of this bootcamp is to transform you
                    into a frontend specialist. Throughout this immersive
                    journey, you will actively participate in live classes and
                    undertake numerous real-world projects. The curriculum has
                    been carefully crafted to ensure that you not only learn the
                    concepts but also put them into practice through various
                    assignments and research work.
                  </p>
                  <v-chip
                    v-for="(item, index) in tech"
                    :key="index"
                    outlined
                    class="mr-2 mb-2"
                    style="border: 1px solid black"
                    >{{ item }}</v-chip
                  >
                  <p>
                    Upon successful completion of the bootcamp, you will possess
                    the skills and knowledge to develop production-ready
                    applications capable of meeting the expectations of real
                    customers. As the number of seats is limited, we encourage
                    you to secure your spot by registering now!
                  </p>
                  <v-btn depressed color="primary" href="https://docs.google.com/forms/d/e/1FAIpQLSeneqxVIlv0COKQ2L9GuXOJhevx5WZkEVhhrXQ0ielUdUPNaA/viewform" target="_blank" rounded>Enroll Now</v-btn>
                </div>
              </v-col>
              <v-col md="4" sm="4" class="text-center">
                <v-img
                  style="background-color: #f0f0f0; border-radius: 12px"
                  :src="require('@/assets/img/cover.png')"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.9)"
                >
                <div class="pa-5">
                  <p class="tf-font mb-0" style="font-size: 110%;">Frontend Development Bootcamp</p>
                  <p class="tf-font" style="font-size: 80%;">Zero to Hero - A Complete Pathway to Frontend Development</p>
                </div>
                </v-img>
              </v-col>
            </v-row>
            <!-- Mission -->

            <!-- Perks & Benifits -->
            <v-row id="perks">
              <v-col md="12">
                <div class="text-center">
                  <p
                    class="mb-0 tf-font"
                    style="color: #1565c0; font-size: 250%"
                  >
                    Perks & Benifits
                  </p>
                  <p>
                    In that timeless instant, I knew we transcended the bounds
                    of reality.
                  </p>
                </div>
                <v-container fluid class="px-0">
                  <v-row>
                    <v-col
                      md="3"
                      cols="12"
                      sm="4"
                      v-for="(item, index) in benifits"
                      :key="index"
                    >
                      <div
                        class="pa-5 pa-sm-2"
                        style="
                          background-color: white;
                          border: 1px solid white;
                          border-radius: 12px;
                        "
                      >
                        <v-container fluid class="my-0 py-0">
                          <v-row align="center" justify="center">
                            <v-col md="2" cols="2" sm="2"
                              ><v-icon size="30" color="#1565C0">{{
                                item.icon
                              }}</v-icon></v-col
                            >
                            <v-col md="10" sm="10" cols="10">{{ item.text }}</v-col>
                          </v-row>
                        </v-container>
                        <!-- <v-icon size="40">{{ item.icon }}</v-icon>  -->
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- Perks & Benifits -->

            <!-- Learning -->
            <v-row class="px-2 my-15" id="learning">
              <v-col
                md="12"
                style="background-color: #f3f9fd; border-radius: 12px"
                class="pa-md-5 pa-4 py-10"
              >
                <div class="text-center">
                  <p
                    class="mb-0 tf-font"
                    style="color: #1565c0; font-size: 250%"
                  >
                    Learning
                  </p>
                  <p class="px-md-15 px-0">
                    Become a frontend specialist in Angular and VueJS with our
                    program. Learn JavaScript, project setup, and real-world
                    deployment. Expert instructors lead hands-on learning,
                    building your portfolio. Embrace the excitement of web
                    development - enroll now!
                  </p>
                </div>
                <br />
                <v-container fluid class="px-5">
                  <v-row>
                    <v-col
                      md="3"
                      cols="12"
                      sm="3"
                      v-for="(item, index) in learning"
                      :key="index"
                    >
                      <v-icon color="success">mdi-clipboard-check</v-icon>
                      {{ item }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- Learning -->

            <!-- Mentors -->
            <v-row id="mentors" justify="center" align="center">
              <v-col md="9">
                <div class="text-center">
                  <p
                    class="mb-0 tf-font"
                    style="color: #1565c0; font-size: 250%"
                  >
                    Mentors
                  </p>
                </div>
                <v-container fluid>
                  <v-row justify="center" align="start">
                    <v-col
                      md="3"
                      sm="4"
                      v-for="(item, index) in mentors"
                      :key="index"
                    >
                      <!-- {{ item }} -->
                      <MentorCardVue :item="item" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- Mentors -->

            <!-- Testimonials -->
            <v-row id="testimonials">
              <v-col md="12">
                <div class="text-center">
                  <p
                    class="mb-0 tf-font"
                    style="color: #1565c0; font-size: 250%"
                  >
                    Testimonial
                  </p>
                </div>
                <v-container fluid>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                      v-for="(item, index) in testimonials"
                      :key="index"
                    >
                      <div
                        class="pa-5"
                        style="
                          background-color: white;
                          border: 1px solid #e0e0e0;
                          border-radius: 12px;
                        "
                      >
                        <p class="tf-font mb-0" style="font-size: 95%">
                          {{ item.content }}
                        </p>

                        <v-list class="mb-0">
                          <v-list-item class="pa-0">
                            <v-list-item-avatar>
                              <v-img :src="item.image"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title style="color: #1565c0">{{
                                item.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                item.position
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- Testimonials -->

            <!-- FAQ -->
            <v-row class="my-10 mx-1" align="start" justify="start" id="faq">
              <v-col md="10">
                <div>
                  <p
                    class="mb-0 tf-font"
                    style="color: #1565c0; font-size: 250%"
                  >
                    Frequently asked questions
                  </p>
                </div>
                <v-expansion-panels flat accordion class="">
                  <v-expansion-panel
                    v-for="(faq, i) in faqs"
                    :key="i"
                    class="px-0 mx-0"
                    style="border-bottom: 1px solid #e0e0e0"
                  >
                    <v-expansion-panel-header
                      class="px-0"
                      style="background-color: #f0f0f0"
                    >
                      <p
                        class="google-font"
                        style="font-size: 110%; margin: auto"
                      >
                        {{ faq.question }}
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      style="
                        background-color: white;
                        border: 1px solid white;
                        border-radius: 12px !important;
                      "
                    >
                      <p
                        class="google-font text-left my-0 mt-5"
                        style="font-size: 100%"
                        v-html="faq.ans"
                      ></p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <!-- FAQ -->
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    
  </v-main>
</template>

<script>
import mentorJSON from "@/assets/data/mentors.json";
import MentorCardVue from "@/components/common/MentorCard.vue";

export default {
  name: "HomePage",
  components: {
    MentorCardVue,
  },
  data: () => ({
    mentors: mentorJSON,
    tech: ["HTML", "CSS", "JavaScript", "Bootstrap", "API", "Angular", "Vue"],
    benifits: [
      {
        icon: "mdi-file-video-outline",
        text: "Live Classes",
        color: "",
      },
      {
        icon: "mdi-code-json",
        text: "Learning through Real-World Projects",
        color: "",
      },
      {
        icon: "mdi-calendar-collapse-horizontal-outline",
        text: "Post-session Assignments",
        color: "",
      },
      {
        icon: "mdi-map-marker-path",
        text: "Join from Any Location",
        color: "",
      },
      {
        icon: "mdi-contain-start",
        text: "Practical Engagement from Day One",
        color: "",
      },
      {
        icon: "mdi-account-group-outline",
        text: "Gateway to the Exclusive Community",
        color: "",
      },
      {
        icon: "mdi-access-point-check",
        text: "Unlimited Lifetime Resource Access",
        color: "",
      },
      {
        icon: "mdi-account-box-outline",
        text: "Instructed by Seasoned Engineering Professionals",
        color: "",
      },
      {
        icon: "mdi-certificate-outline",
        text: "Course and Project Completion Certificate",
        color: "",
      },
      {
        icon: "mdi-account-details-outline",
        text: "Front-Row Access to All Techferment Webinars",
        color: "",
      },
      {
        icon: "mdi-linkedin",
        text: "Opportunity to Speak and Contribute Your Knowledge",
        color: "",
      },
      {
        icon: "mdi-file-document-outline",
        text: "Bonus Session: Open Source Contribution Workshop",
        color: "",
      },
    ],
    learning: [
      "Web Fundamentals 101",
      "Javascript",
      "TypeScript",
      "VueJS",
      "Vue Router",
      "State Management",
      "Caching",
      "Configuration driven UI",
      "Vuetify",
      "Tailwind CSS",
      "Vue Components",
      "Angular",
      "Angular Components",
      "Angular Directives",
      "Angular Pipes",
      "Angular Signals",
      "Angular Http Client",
      "Dynamic UI",
      "Performance Optimization",
      "Deployments",
      "CDN",
      "... and much more",
    ],
    testimonials: [
      {
        name: "Kartik Derasari",
        position: "GDE for Google Cloud",
        content:
          "I am extremely grateful to Vrijraj and TechFerment for bringing forth boot camps and mentorship programs. His constant support and teaching have helped me kickstart my journey in web development and open-source. I really appreciate all his time and efforts in building community projects, resources, blogs and video content.",
        image:
          "https://media.licdn.com/dms/image/D5603AQGhMXwifsDnVw/profile-displayphoto-shrink_800_800/0/1681884067166?e=1695859200&v=beta&t=kUeefyI5Ye3slzKMqy9mCKbw99ELlQqCGgab8d3J-Y8",
      },
      {
        name: "Sherya Garg",
        position: "Software Engineer, Gemini Solutions Pvt Ltd",
        content:
          "I completed frontend development bootcamp in my final year with Vrijraj Sir. He was so approachable and generous with his knowledge. He was always open to exploring any topics I raised and we took a deep dive into frontend technologies as well as in backend which I now feel very confident tackling! He is a fantastic teacher, and I would recommend to anyone looking to up-skill their knowledge in full-stack.",
        image:
          "https://media.licdn.com/dms/image/D4D03AQECcWjZBGCt6w/profile-displayphoto-shrink_800_800/0/1685211377477?e=1695859200&v=beta&t=G2dZDra1XwqbA4cxVfo9nrCKtEjoi9OmHE31UfVaQzs",
      },
      {
        name: "Vinayak Singh",
        position: "Member Technical, Broadridge India",
        content:
          "I had taken part in the Frontend Development Bootcamp during my second year of engineering. Vrijraj is an excellent mentor, and helped me throughout my learning phase. Learning different web technologies with the latest resources was a breeze during the bootcamp and I was able to develop several projects to write down on my resume as well. And his YouTube channel tech ferment is another great place to learn new stuff.",
        image: "https://media.licdn.com/dms/image/D4D35AQEeJA1AMxGVIw/profile-framedphoto-shrink_800_800/0/1686913316600?e=1691078400&v=beta&t=jeeMgLWPBE08_POK_hs91Smuoawk5JugMqsqW5b6vDo",
      },
    ],
    faqs: [
      {
        question: "Are there any prerequisites for joining the bootcamp?",
        ans: "No prior experience is necessary to enroll in the Frontend Development Online Bootcamp. However, basic computer skills and familiarity with web browsing are recommended. Having a passion for coding and problem-solving will be advantageous.",
      },
      {
        question: "Who is this bootcamp suitable for?",
        ans: "This bootcamp is ideal for beginners and aspiring frontend developers who want to kickstart their careers in web development. It is also suitable for professionals from related fields, designers, or individuals looking to enhance their frontend development skills.",
      },
      {
        question: "How is the bootcamp structured?",
        ans: "The bootcamp is structured into modules, each covering specific topics, skills, or technologies. The content is delivered through video lectures, interactive coding exercises, quizzes, and projects. Live coding sessions and Q&A with instructors may also be available.",
      },
      {
        question: "What language will the course be taught in?",
        ans: "Everything will be taught in Hindi",
      },
      {
        question: "Will there be recordings of the sessions?",
        ans: "Unlimited Lifetime Resource Access",
      },
      
      {
        question: "What kind of projects will I work on?",
        ans: "Throughout the bootcamp, you will work on various real-world projects that simulate typical frontend development scenarios. These projects will enable you to apply the knowledge gained during the course and build a portfolio to showcase your skills to potential employers.",
      },
      {
        question: "Will I receive a certificate upon completion?",
        ans: "Yes, upon successfully completing the bootcamp and meeting the necessary requirements, you will typically receive a certificate of completion. This certificate can be a valuable addition to your resume and may demonstrate your dedication to potential employers.",
      },
      {
        question: "Can I find a job immediately after completing the bootcamp?",
        ans: "While completing the bootcamp will significantly improve your employability, job outcomes can vary based on your location, the job market demand, and your overall skills and experience. Many bootcamps also provide job assistance or career support, helping you with resume building, interview preparation, and job search strategies.",
      },
      {
        question: "I have more questions, how can I reach out to you?",
        ans: "Drop us a mail at techferment@gmail.com",
      },
    ],
  }),
};
</script>

<style>
</style>
