<template>
  <v-navigation-drawer
    clipped
    floating
    app
    width="300px"
    v-model="drawer"
    temporary
    style="border: 1px solid #dadce0 !important; background-color: white"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="google-font py-1" style="font-size: 130%">
          Frontend Bootcamp
        </v-list-item-title>
        <v-list-item-subtitle> By TechFerment </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item to="/" class="google-font my-0 py-0" color="#1565c0">
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/#mentors" class="google-font my-0 py-0" color="#1565c0">
        <v-list-item-content>
          <v-list-item-title>Mentors</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/terms-and-conditions" class="google-font my-0 py-0" color="#1565c0">
        <v-list-item-content>
          <v-list-item-title>Terms & Conditions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/privacy-and-policy" class="google-font my-0 py-0" color="#1565c0">
        <v-list-item-content>
          <v-list-item-title>Privacy and Policy</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>
    
    <script>
export default {
  name: "AppDrawer",
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("setDrawer", val);
      },
    },
  },
  methods: {},
};
</script>
    
    <style>
</style>