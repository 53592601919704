<template>
  <v-dialog v-model="dialog" width="700" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <!--  style="border: 1px solid #212121; border-radius: 12px" -->
      <div class="mx-auto text-center py-7" v-on="on" v-bind="attrs">
        <v-avatar size="150">
          <v-img
            :src="getProfileImage(item.image)"
            :lazy-src="getProfileImage(item.image)"
            :alt="item.name"
          ></v-img>
        </v-avatar>
        <p class="mb-1 mt-4 tf-font" style="font-size: 130%; font-weight: 600">
          {{ item.name }}
        </p>
        <p class="my-0" style="font-size: 90%">
          {{ item.company.designation }}, {{ item.company.name }}
        </p>
        <p class="my-0" style="font-size: 90%">{{ item.company.extra }}</p>
      </div>
    </template>
    <v-card v-if="dialog" style="border-radius: 12px">
      <v-card-text class="pa-0">
        <v-container fluid class="">
          <v-row class="mt-2 mb-0">
            <v-col md="12" cols="12" class="px-md-8 px-3">
              <v-list class="pa-0 ma-0">
                <v-list-item class="pa-0 ma-0">
                  <v-list-item-avatar size="60">
                    <v-img
                      :src="getProfileImage(item.image)"
                      :lazy-src="getProfileImage(item.image)"
                      :alt="item.name"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="mb-2 tf-font"
                      style="font-size: 200%; font-weight: 600"
                      >{{ item.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="mb-0 tf-font"
                      style="font-size: 110%; font-weight: 500"
                      >{{ item.company.designation }} /
                      {{ item.company.extra }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-if="item.company.name"
                      class="mb-0 tf-font"
                      style="font-size: 110%"
                    >
                      {{ item.company.name }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="px-md-8 px-5">
              <p class="mb-0" style="font-size: 110%">
                <b>About {{ item.name }}</b>
              </p>
              <p class="" style="font-size: 110%">
                {{ item.bio }}
              </p>
              <socialMediaDetails
                class="pl-0 ml-0 mb-2"
                :socialInfo="item.social"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <!-- <v-divider></v-divider> -->

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn aria-label="close" class="px-5" text dark @click="dialog = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import socialMediaDetails from "@/components/common/PersonSocialMedia.vue";
export default {
  name: "SpeakerDetails",
  components: {
    socialMediaDetails,
  },
  props: ["item", "type"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    getProfileImage(img) {
      // console.log('Cas');
      if (img.length == 0) {
        return "https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg";
      }
      return require("@/assets/img/mentors/" + img);
    },
  },
};
</script>