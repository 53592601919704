<template>
   <v-app-bar
   app
    flat
    fixed
    class="px-1 mx-6 mt-5 mt-md-3 mx-md-auto"
    style="
      align-items: center;
      max-width: 1024px;
      margin: auto;
      border-radius: 20px;
      -webkit-backdrop-filter: saturate(100%) blur(5px);
      backdrop-filter: saturate(100%) blur(10px);
      background-color: #E9F1FB;
      border: 1px solid #dadce0 !important;background-color: rgba(255, 255, 255, 0.6)
    "
    
    >
    <v-app-bar-nav-icon class="d-md-none d-lg-none" @click="ToggelToobar"></v-app-bar-nav-icon>

    <v-toolbar-title class="px-0" >
       <router-link class="grey--text text--darken-2 tf-font" style="text-decoration: none; font-size: 100%" to="/">Frontend Bootcamp</router-link> 
    </v-toolbar-title>
      <!-- <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div> -->

      
      <span class="mx-3"></span>
      <div  class="hidden-sm-and-down vrij-font">
      <!-- <v-btn text href="#home">Home</v-btn> -->
      <v-btn text small class="tf-font" href="/#mission" style="text-transform: capitalize;color: #1565c0">Mission</v-btn>
      <v-btn text small class="tf-font" href="/#perks" style="text-transform: capitalize;color: #1565c0">Perks</v-btn>
      <v-btn text small class="tf-font" href="/#learning" style="text-transform: capitalize;color: #1565c0">Learning</v-btn>
      <v-btn text small class="tf-font" href="/#mentors" style="text-transform: capitalize;color: #1565c0">Mentors</v-btn>
      <v-btn text small class="tf-font" href="/#testimonials" style="text-transform: capitalize;color: #1565c0">Testimonial</v-btn>
      <v-btn text small class="tf-font" href="/#faq" style="text-transform: capitalize;color: #1565c0">FAQ</v-btn>
    </div>
      <v-spacer></v-spacer>
      <v-btn
        href="https://docs.google.com/forms/d/e/1FAIpQLSeneqxVIlv0COKQ2L9GuXOJhevx5WZkEVhhrXQ0ielUdUPNaA/viewform"
        target="_blank"
        rounded
        color="primary"
        class="hidden-sm-and-down"
        depressed
      >
        <span>Enroll Now</span>
      </v-btn>
    </v-app-bar>
</template>

<script>
export default {

  methods:{
    ToggelToobar(){
      this.$store.commit('toggleDrawer')
    },
  }
}
</script>

<style>

</style>